<template>
  <te-navbar href-header="/" header-tag="router-link" class="bg-own-blue shadow-none fixed top-0" dark @toggle-click="$emit('toggle', $event)">
    <template slot="brand">
      <img src="../assets/logo.png" class="h-8 bg-white rounded-md" alt="" loading="lazy" />
    </template>
    <span class="text-2xl text-white ml-2">@ajomuch92</span>
    <template #mobile-title>
      <span class="text-2xl text-white ml-2">@ajomuch92</span>
    </template>
    <template v-if="isDesktop" slot="right">
      <router-link class="mx-4 text-white hover:text-black hover:bg-gray-100 px-3 py-2 rounded-md" active-class="bg-gray-100 text-own-blue" to="/technologies">Tecnologies</router-link>
      <router-link class="mx-4 text-white hover:text-black hover:bg-gray-100 px-3 py-2 rounded-md" active-class="bg-gray-100 text-own-blue" to="/projects">Projects</router-link>
      <router-link class="mx-4 text-white hover:text-black hover:bg-gray-100 px-3 py-2 rounded-md" active-class="bg-gray-100 text-own-blue" to="/certifications">Certifications</router-link>
      <!-- <te-switch /> -->
    </template>
  </te-navbar>
</template>

<script>
import VueBreakpointMixin from 'vue-breakpoint-mixin';

export default {
  name: 'NavigationHeaderBar',
  mixins: [VueBreakpointMixin],
}
</script>

<style>

</style>