<template>
  <main class="home lg:h-custom w-full flex justify-center items-center sm:h-full xsm:h-full">
    <section class="h-2/3 2xl:w-2/5 xl:w-3/5 lg:w-3/4 md:w-5/6 sm:w-5/6  bg-white rounded-md lg:shadow-lg">
      <div class="flex items-center lg:justify-center lg:flex-row md:flex-col sm:flex-col xsm:flex-col">
        <img class="rounded-full h-64" src="../assets/my-photo.png" />
        <typer class="lg:text-4xl sm:text-3xl xsm:text-2xl" :text="['Hello World!', 'My name is Aarón Montes']" :loop="false" />
      </div>
      <p class="text-xl px-10 text-justify">
        I am a Honduran software engineer, graduated from Universidad Nacional Autónoma de Honduras (UNAH) with a Master Degree in AI. Open Source,
        FullStack and mobile developer, and also teacher.
        I started writing code {{ yearsOfExperience }} years ago, although in web development my first approach was in 2015. 
        I have had the opportunity to attend workshops, be a team project leader, collaborate with other Open Source projects, talk to others about my experience
        and my packages are being used by many companies around the world.
      </p>
      <div class="flex justify-center mt-8">
        <animated-link href="https://github.com/ajomuch92">
          <te-icon class="text-3xl" name="github"/> 
        </animated-link>
        <animated-link href="https://stackoverflow.com/users/7287523/aar%c3%b3n-josu%c3%a9-montes-chicas">
          <te-icon class="text-3xl" name="stack-overflow"/> 
        </animated-link>
        <animated-link href="https://www.linkedin.com/in/aar%C3%B3n-josu%C3%A9-montes-chicas-17a719170/">
          <te-icon class="text-linkedin text-3xl" name="linkedin"/> 
        </animated-link>
        <animated-link href="https://twitter.com/ajomuch92">
          <te-icon class="text-own-blue text-3xl" name="twitter"/> 
        </animated-link>
        <animated-link href="https://www.youtube.com/@aaronjosuemontes5875">
          <te-icon class="text-youtube text-3xl" name="youtube"/> 
        </animated-link>
        <animated-link href="mailto:ajomuch92@gmail.com">
          <te-icon class="text-green-400 text-3xl" name="envelope-at"/> 
        </animated-link>
      </div>
      <div class="mt-5 text-center">
        <span class="bg-visitor text-white px-2 py-1 rounded-l-md shadow-sm">{{ visitors }}</span>
        <span class="bg-black px-2 py-1 text-white rounded-r-md shadow-sm">Visitors</span>
      </div>
    </section>
  </main>
</template>

<script>
import supabase from '../data/supabase';
import Typer from '../components/Typer.vue';
import AnimatedLink from '../components/AnimatedLink.vue';

export default {
  name: 'Home',
  components: { Typer, AnimatedLink },
  data: () => ({
    visitors: 0,
  }),
  computed: {
    yearsOfExperience() {
      return new Date().getFullYear() - 2008;
    }
  },
  created() {
    this.getVisitors();
  },
  methods: {
    async getVisitors() {
      const result = await supabase.from('page_log').select('id', { count: "exact"});
      if (!result.error) {
        this.visitors = result.count;
      }
    }
  }
}
</script>
