<template>
  <span :key="index" :style="style">{{ currentText }}</span>
</template>

<script>
export default {
  name: 'Typer',
  props: {
    text: {
      type: [String, Array],
      required: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    index: 0,
    interval: undefined,
  }),
  beforeDestroy() {
    if (this.interval) {
      clearImmediate(this.interval);
    }
  },
  computed: {
    currentText() {
      if (Array.isArray(this.text)) {
        return this.text[this.index];
      }
      return this.text;
    },
    style() {
      return {
        '--width': `${this.currentText.length}ch`,
        '--steps': this.currentText.length,
      };
    },
  },
  mounted() {
    if (Array.isArray(this.text)) {
      this.setLoop();
    }
  },
  methods: {
    setLoop() {
      this.interval = setInterval(() => {
        if (this.index === this.text.length - 1) {
          if (!this.loop) {
            clearInterval(this.interval);
            this.interval = undefined;
          } else {
            this.index = 0;
          }
        } else {
          this.index += 1;
        }
      }, 3000);
    },
  }
}
</script>

<style scoped>
  span {
    display: block;
    font-family: monospace;
    white-space: nowrap;
    border-right: 3px solid;
    width: calc(var(--width) + 2px);
    animation: typing 2s steps(var(--steps)), blink 0.5s infinite step-end alternate;
    overflow: hidden;
  }

  @keyframes typing {
    from { width: 0;}
  }

  @keyframes blink {
    50% { border-color: transparent; }
  }
</style>