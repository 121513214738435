import Vue from 'vue';

const global = new Vue({

});

const install = () => {
  Vue.prototype.$global = global;
};

export default install;