<template>
  <div id="app">
    <navigation-header-bar @toggle="toggle"/>
    <router-view/>
    <te-offcanvas v-model="open">
      <div class="px-4 py-10">
        <h1 class="text-3xl">@ajomuch92</h1>
        <router-link class="w-full px-4 py-2 rounded-md text-2xl inherit my-3" to="/" exact-active-class="bg-own-blue text-white" @click.native="open=false">
          <te-icon name="house" />
          Home
        </router-link>
        <router-link class="w-full px-4 py-2 rounded-md text-2xl inherit my-3" to="/technologies" exact-active-class="bg-own-blue text-white" @click.native="open=false">
          <te-icon name="code" />
          Technologies
        </router-link>
        <router-link class="w-full px-4 py-2 rounded-md text-2xl inherit my-3" to="/projects" exact-active-class="bg-own-blue text-white" @click.native="open=false">
          <te-icon name="braces-asterisk" />
          Projects
        </router-link>
        <router-link class="w-full px-4 py-2 rounded-md text-2xl inherit my-3" to="/certifications" exact-active-class="bg-own-blue text-white" @click.native="open=false">
          <te-icon name="patch-check" />
          Certifications
        </router-link>
      </div>
    </te-offcanvas>
  </div>
</template>

<script>
import axios from 'axios';
import supabase from './data/supabase';
import NavigationHeaderBar from './components/NavigationHeaderBar.vue';

export default {
  name: 'App',
  components: { NavigationHeaderBar },
  data: () => ({
    open: false,
  }),
  async created() {
    await this.getIPAddress();
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    async getIPAddress() {
      try {
        if (process.env.NODE_ENV === 'development') return;
        const url = process.env.VUE_APP_IP_ADDRESS_URL;
        const { data, status } = await axios.get(url);
        if(status === 200) {
          const { ipAddress, continentName, countryCode, countryName } = data;
          if (ipAddress && continentName && countryCode && countryName) {
            const date = new Date();
            await supabase
            .from('page_log')
            .insert([
              { ipAddress, continentName, countryCode, countryName, date},
            ]);
          }
        }
      } catch {
        console.log('Err');
      }
    },
  }
}
</script>

<style>
  .inherit {
    display: inherit;
  }
</style>
