import Vue from 'vue';
import VueTailwindElements from 'vue-tailwind-elements';
import Meta from 'vue-meta';
import App from './App.vue';
import router from './router';
import GlobalBus from './plugins/globalBus';
import './styles/tailwind.css';

Vue.config.productionTip = false;

Vue.use(VueTailwindElements);
Vue.use(GlobalBus);
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'ajomuch92',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'ajomuch92',
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
