<template>
  <a :href="href" target="_blank" class="h-8 w-8 flex items-center justify-center mx-1 transform hover:scale-125 transition duration-50 ease-linear">
    <slot name="default"/> 
  </a>
</template>

<script>
export default {
  name: 'AnimatedLink',
  props: {
    href: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>